import React from "react"
import * as styles from "./style.module.scss"


const SKIN_COLOR = "#5a3d3d";
const Human = ({ className }) => {
  return (
    <svg
      className={className}
      style={{ perspective: "10px" }}
      width="675"
      height="608"
      viewBox="0 0 675 608"
      fill="none"
    >
      <g id="Human">
        <g id="Ellipse 277" >
          <ellipse
            cx="337.449"
            cy="521.234"
            rx="333.449"
            ry="78.7019"
            fill="white"
          />
        </g>
        <g id="Vector 1"  >
          <path
            d="M150.693 516.362C39.1323 477.607 102.617 465.086 116.793 460.913L499 452C517.285 454.584 560.664 461.979 566.118 463.298C575.979 465.683 610.5 470 612.345 482.377C614.189 494.754 607.442 499.956 581.5 509.5C548.857 521.509 488.424 529.329 474 528L450.5 526L457.5 518.5L453.941 514.573L424.356 506.822L242.53 484.166C215.205 484.563 154.145 509.684 150.693 516.362Z"
            fill="#5f6e84"
          />
        </g>
        <g id="Vector"  >
          <path
            d="M158.82 497.094L157.521 521.805L255.238 537.467L257.156 500.994L158.82 497.094Z"
            fill={`${SKIN_COLOR}`}
          />
        </g>
        <g id="Vector_2"  >
          <path
            d="M190.251 477.759L180.277 525.461L180.277 525.463C178.59 533.525 173.662 540.609 166.577 545.156C159.491 549.704 150.829 551.342 142.494 549.712L141.473 549.512L157.804 471.411L190.251 477.759Z"
            fill="black"
          />
        </g>
        <g id="Head" className={styles.head}>
          <g id="Ellipse 276"  >
            <path
              d="M287.499 148.693C322.813 148.693 351.441 121 351.441 86.839C351.441 52.6781 322.813 24.9852 287.499 24.9852C252.185 24.9852 223.557 52.6781 223.557 86.839C223.557 121 252.185 148.693 287.499 148.693Z"
              fill={`${SKIN_COLOR}`}
            />
          </g>
          <g id="Path 1461"  >
            <path
              d="M347.222 33.4766C337.662 18.4599 322.697 7.39464 305.209 2.41086L297.359 7.95217V0.756913C293.159 0.135096 288.909 -0.104451 284.662 0.0414343L277.89 5.96913V0.533929C267.863 1.73929 258.202 4.94135 249.518 9.93822C240.833 14.9351 233.314 21.6183 227.432 29.5666C212.725 50.0768 210.242 78.611 224.708 99.2857C228.679 87.4804 233.499 76.4037 237.47 64.5996C240.591 65.0112 243.755 65.026 246.88 64.6438L251.711 53.7388L253.061 64.1823C268.036 62.9207 290.247 60.1494 304.443 57.6107L303.062 49.5986L311.321 56.2561C315.67 55.2878 318.253 54.409 318.039 53.7376C328.598 70.203 341.519 80.7192 352.076 97.1846C356.086 73.9676 360.701 54.663 347.222 33.4766Z"
              fill="#0C0C0D"
            />
          </g>
        </g>
        <g id="Ellipse 260"  >
          <path
            d="M308.303 491.597C399.232 491.597 472.945 471.652 472.945 447.048C472.945 422.444 399.232 402.499 308.303 402.499C217.375 402.499 143.662 422.444 143.662 447.048C143.662 471.652 217.375 491.597 308.303 491.597Z"
            fill="black"
          />
        </g>
        <g id="Vector_3"  >
          <path
            d="M425.02 245.898C422.822 233.102 420.553 220.06 414.406 208.532C410.365 200.982 403.951 193.787 395.305 192.294C393.601 192.147 391.928 191.772 390.331 191.18C387.797 189.999 353.752 171.199 348.341 167.925C343.695 165.114 336.361 159.909 332.662 159.909C328.942 159.83 314.676 163.041 258.995 158.831C249.629 162.468 240.559 166.781 231.863 171.735C231.683 171.621 174.071 201.548 171.906 201.47C167.807 201.303 163.997 203.841 161.647 207.038C159.298 210.235 158.196 214.193 157.042 218.02C169.626 245.128 181.139 272.27 193.724 299.378C194.313 300.399 194.626 301.548 194.634 302.717C194.381 303.931 193.846 305.073 193.068 306.056C186.897 315.646 187.093 327.707 187.767 338.978C188.442 350.25 189.276 362.155 184.069 372.257C182.653 375.031 180.811 377.57 179.464 380.344C176.309 386.625 175.169 406.717 177.012 413.466L407.858 419.864C402.895 406.665 425.02 245.898 425.02 245.898Z"
            fill="black"
          />
        </g>
        <g id="Path 1421"  >
          <path
            d="M137.088 300.58C136.627 303.803 136.581 307.069 136.95 310.303L140.259 356.268C140.569 360.6 140.875 364.921 141.295 369.243C142.089 377.629 143.275 385.949 144.747 394.257C144.78 395.437 145.296 396.555 146.181 397.367C147.066 398.18 148.248 398.619 149.467 398.589C164.031 401.57 179.079 401.451 193.943 400.639C216.625 399.414 274.654 397.097 278.199 392.642C281.745 388.187 279.679 380.981 275.056 377.379C270.433 373.777 193.856 364.994 193.856 364.994C194.604 359.258 196.861 353.857 199.002 348.411C202.848 338.752 206.441 328.776 206.51 318.429C206.579 308.082 202.549 297.203 193.787 291.275C186.579 286.409 177.299 285.506 168.516 285.707C162.126 285.884 151.084 284.403 145.247 286.82C140.628 288.788 137.951 296.205 137.088 300.58Z"
            fill={`${SKIN_COLOR}`}
          />
        </g>
        <g id="Path 1423"  >
          <path
            d="M421.573 404.85C425.406 403.435 429.632 403.947 433.742 404.482C446.972 406.23 460.396 408.024 472.658 413.136C478.414 415.521 483.895 418.705 487.787 423.391C491.238 427.612 493.255 432.78 495.167 437.87L499.589 449.587C501.469 453.988 502.765 458.603 503.446 463.319C504.828 477.341 494.914 490.561 482.445 497.846C469.977 505.13 455.297 507.724 440.997 510.441C426.696 513.159 412.34 516.165 398.098 519.351C391.455 521.019 384.716 522.302 377.915 523.193C362.671 524.863 346.737 522.403 332.253 527.281C326.496 529.231 320.843 532.126 314.871 533.474C310.902 534.281 306.889 534.873 302.851 535.244L275.438 538.273C269.864 538.994 264.252 539.411 258.629 539.521C246.655 539.521 227.181 542.263 215.554 539.521C214.319 539.31 212.54 535.923 211.5 535.244C208.143 530.5 208.408 529.53 208.143 527.281C206.969 517.324 215.087 506.627 214.207 496.648C213.931 493.407 213.724 489.899 215.554 487.171C217.857 483.832 222.348 482.782 226.446 482.136C243.827 479.386 261.434 478.204 279.04 478.605C285.729 472.135 296.759 472.102 305.521 468.581C308.745 467.149 311.864 465.508 314.859 463.67C330.532 454.824 348.046 449.471 366.128 448C369.669 447.895 373.187 447.41 376.617 446.552C383.397 444.514 389.581 438.967 396.546 440.326C397.409 438.021 398.008 434.935 399.998 433.354C400.984 432.541 402.208 431.996 403.118 431.126C405.041 429.245 405.213 426.282 404.925 423.653C404.636 421.025 403.992 418.33 404.694 415.768C404.987 414.798 405.417 413.872 405.973 413.017C409.414 407.255 414.63 404.293 421.573 404.85Z"
            fill="black"
          />
        </g>
        <g id="Path 1430"  >
          <path
            d="M156.376 218.845C155.255 219.981 154.385 221.326 153.821 222.799C144.514 243.088 139.017 264.818 137.587 286.972C137.612 288.052 137.355 289.121 136.839 290.079C136.33 290.717 135.764 291.31 135.147 291.85C134.608 292.467 134.201 293.181 133.948 293.95C133.695 294.72 133.601 295.53 133.672 296.334C133.742 297.139 133.976 297.922 134.359 298.639C134.743 299.356 135.269 299.994 135.907 300.514C136.011 300.599 136.119 300.681 136.229 300.76C138.128 297.302 142.676 296.172 146.718 295.871C166.072 294.39 184.977 303.1 204.388 302.443C203.019 297.865 201.049 293.466 199.932 288.833C194.992 268.262 207.312 245.866 199.759 226.052C198.25 222.087 195.729 218.189 191.758 216.451C190.113 215.81 188.392 215.369 186.635 215.14C181.73 214.282 171.965 210.618 167.223 212.088C165.473 212.634 164.782 214.226 163.331 215.189C161.127 216.55 158.203 217.051 156.376 218.845Z"
            fill="black"
          />
        </g>
        <g id="Path 1421_2"  >
          <path
            d="M425.158 286.82C419.322 284.403 408.28 285.884 401.89 285.707C393.107 285.506 383.827 286.409 376.619 291.275C367.857 297.203 363.827 308.082 363.896 318.429C363.965 328.776 367.557 338.752 371.403 348.411C373.545 353.857 375.802 359.258 376.55 364.994C376.55 364.994 299.973 373.777 295.35 377.379C290.727 380.981 288.661 388.187 292.207 392.642C295.752 397.097 406.374 401.57 420.939 398.589C422.158 398.619 423.34 398.18 424.225 397.367C425.11 396.555 425.626 395.437 425.659 394.257C427.131 385.949 428.317 377.629 429.111 369.243C429.531 364.921 429.837 360.6 430.147 356.268L433.456 310.303C433.825 307.069 433.778 303.803 433.318 300.58C432.455 296.205 429.777 288.788 425.158 286.82Z"
            fill={`${SKIN_COLOR}`}
          />
        </g>
        <g id="Path 1430_2"  >
          <path
            d="M407.075 215.189C405.624 214.226 404.933 212.634 403.183 212.088C398.441 210.618 388.676 214.282 383.771 215.14C382.014 215.369 380.293 215.81 378.648 216.451C374.676 218.189 372.155 222.087 370.647 226.052C363.094 245.866 375.414 268.262 370.474 288.833C369.357 293.466 367.387 297.865 366.018 302.443C385.429 303.1 404.334 294.39 423.688 295.871C427.73 296.172 432.277 297.302 434.177 300.76C434.287 300.682 434.395 300.599 434.499 300.514C435.137 299.994 435.663 299.356 436.046 298.639C436.43 297.922 436.664 297.139 436.734 296.334C436.805 295.53 436.711 294.72 436.458 293.95C436.205 293.181 435.798 292.467 435.259 291.85C434.642 291.31 434.076 290.717 433.567 290.079C433.051 289.121 432.793 288.052 432.819 286.972C431.389 264.818 425.891 243.088 416.585 222.799C416.021 221.326 415.151 219.981 414.03 218.845C412.203 217.051 409.279 216.55 407.075 215.189Z"
            fill="black"
          />
        </g>
        <g id="Vector_4"  >
          <path
            d="M459.507 516.882L451.188 548.443L334.42 531.395L346.7 484.814L459.507 516.882Z"
            fill={`${SKIN_COLOR}`}
          />
        </g>
        <g id="Vector_5"  >
          <path
            d="M464.854 513.823L454.001 592.811L452.969 592.679C444.54 591.595 436.901 587.316 431.733 580.785C426.564 574.253 424.29 566.003 425.409 557.849L425.41 557.847L432.038 509.604L464.854 513.823Z"
            fill="black"
          />
        </g>
        <g id="Path 1427"  >
          <path
            d="M205.881 416.879C205.881 417.993 204.074 416.879 203.909 415.686C202.643 406.676 195.25 399.247 186.638 395.639C178.027 392.031 168.217 391.841 158.891 392.698C144.788 393.99 128.956 399.214 123.798 411.966C122.578 414.947 111.146 441.491 112.261 447.461C115.267 463.387 126.526 477.008 140.17 486.442C153.814 495.875 169.755 501.511 185.657 506.594C227.7 520.008 270.836 529.973 314.608 536.387C321.699 537.423 328.861 538.38 335.654 540.585C341.732 542.556 347.42 545.508 353.362 547.825C367.178 553.191 382.042 555.108 396.767 556.991C399.277 557.303 402.155 557.525 404.009 555.877C406.404 553.731 405.611 549.985 404.93 546.9C402.431 535.17 404.528 522.961 410.814 512.642C413.279 508.633 416.484 504.523 416.018 499.883C415.466 494.471 409.893 490.639 404.343 489.77C398.793 488.901 393.152 490.183 387.578 490.884C385.668 491.308 383.666 491.116 381.879 490.338C381.051 489.797 380.3 489.153 379.646 488.423C375.807 484.84 370.894 482.526 365.611 481.812C360.328 481.098 354.948 482.02 350.24 484.447C348.179 485.561 345.063 483.077 342.918 482.142L318.705 471.539C290.889 459.366 262.935 447.136 233.506 439.429C231.158 438.983 228.926 438.085 226.943 436.789C225.481 435.676 224.433 434.166 223.086 432.925C220.15 430.24 216.059 429.171 212.54 427.266C209.021 425.362 205.075 420.698 205.881 416.879Z"
            fill="black"
          />
        </g>
        <g id="Laptop" className={styles.laptop}>
          <g id="Vector_6"  >
            <path
              d="M413.305 421.708H176.206C173.438 421.705 170.784 420.64 168.826 418.747C166.869 416.854 165.768 414.286 165.765 411.609V272.465C165.768 269.788 166.869 267.221 168.826 265.327C170.784 263.434 173.438 262.369 176.206 262.366H413.305C416.073 262.369 418.727 263.434 420.684 265.327C422.642 267.221 423.743 269.788 423.746 272.465V411.609C423.743 414.286 422.642 416.854 420.684 418.747C418.727 420.64 416.073 421.705 413.305 421.708Z"
              fill="#94a3b8"
            />
          </g>
          <g id="Ellipse 263"  >
            <path
              d="M294.18 348.72C297.995 348.72 301.088 345.728 301.088 342.037C301.088 338.346 297.995 335.355 294.18 335.355C290.365 335.355 287.272 338.346 287.272 342.037C287.272 345.728 290.365 348.72 294.18 348.72Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      
    </svg>
  )
}

export default Human

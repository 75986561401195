import { motion } from "framer-motion";
import React from "react";
import * as styles from "./style.module.scss";

const SKIN_COLOR = "#8f6262";
const StandingHuman = ({ className }) => {
  return (
    <svg
      width="1517"
      height="1024"
      viewBox="0 0 1517 1024"
      fill="none"
      className={className}
    >
      <path
        d="M1412.92 879.951L516.212 945.215C510.09 945.654 504.038 943.561 499.384 939.397C494.73 935.232 491.853 929.336 491.385 923.002L449.737 350.778C449.404 346.137 450.864 341.558 453.796 338.045C456.728 334.533 460.893 332.374 465.377 332.042L1374.14 265.9C1378.7 265.573 1383.21 267.133 1386.68 270.237C1390.15 273.34 1392.29 277.735 1392.64 282.456L1434.26 854.377C1434.72 860.712 1432.73 866.962 1428.72 871.757C1424.72 876.551 1419.04 879.498 1412.92 879.951V879.951Z"
        fill="#F2F2F2"
      />
      <path
        d="M1381.73 851.893L546.023 912.718C533.772 913.61 523.103 904.676 522.239 892.804L485.971 394.505C485.33 385.7 492.194 377.999 501.272 377.339L1348.21 315.696C1357.44 315.025 1365.48 321.76 1366.13 330.709L1402.38 828.746C1403.24 840.618 1393.98 851.002 1381.73 851.893Z"
        fill="white"
      />
      <path
        d="M1392.51 285.154L449.63 353.779L448.744 341.615C448.291 335.258 450.292 328.987 454.309 324.178C458.326 319.369 464.031 316.415 470.172 315.962L1366.71 250.71C1372.85 250.268 1378.92 252.366 1383.6 256.542C1388.27 260.719 1391.15 266.634 1391.63 272.989L1392.51 285.154Z"
        fill="#615959"
      />
      <path
        d="M912.982 456.904L749.537 468.8C747.1 468.978 744.69 468.146 742.837 466.488C740.984 464.83 739.84 462.482 739.657 459.96C739.473 457.439 740.265 454.95 741.858 453.041C743.452 451.133 745.716 449.961 748.152 449.783L911.598 437.887C914.035 437.71 916.445 438.542 918.298 440.199C920.151 441.857 921.295 444.205 921.478 446.727C921.662 449.249 920.87 451.738 919.276 453.646C917.683 455.555 915.419 456.727 912.982 456.904V456.904Z"
        fill="#CCCCCC"
      />
      <path
        d="M1116.08 442.122L952.635 454.018C950.198 454.196 947.788 453.364 945.935 451.706C944.082 450.048 942.938 447.7 942.755 445.179C942.571 442.657 943.363 440.168 944.956 438.259C946.55 436.351 948.814 435.179 951.25 435.001L1114.7 423.105C1117.13 422.928 1119.54 423.76 1121.4 425.417C1123.25 427.075 1124.39 429.423 1124.58 431.945C1124.76 434.467 1123.97 436.956 1122.37 438.864C1120.78 440.773 1118.52 441.945 1116.08 442.122V442.122Z"
        fill="#CCCCCC"
      />
      <path
        d="M1118.7 478.154L834.366 498.849C831.929 499.026 829.519 498.195 827.666 496.537C825.813 494.879 824.669 492.531 824.486 490.009C824.302 487.488 825.094 484.999 826.687 483.09C828.281 481.181 830.545 480.01 832.981 479.832L1117.32 459.137C1119.76 458.96 1122.17 459.792 1124.02 461.449C1125.87 463.107 1127.02 465.455 1127.2 467.977C1127.38 470.499 1126.59 472.988 1125 474.896C1123.4 476.805 1121.14 477.977 1118.7 478.154Z"
        fill="#CCCCCC"
      />
      <path
        d="M910.323 420.372L746.878 432.268C744.443 432.442 742.036 431.609 740.185 429.951C738.335 428.293 737.192 425.947 737.009 423.427C736.826 420.907 737.616 418.42 739.207 416.512C740.798 414.604 743.059 413.431 745.493 413.251L908.939 401.355C910.147 401.265 911.36 401.423 912.511 401.819C913.662 402.215 914.727 402.841 915.646 403.662C916.564 404.483 917.318 405.483 917.865 406.603C918.411 407.724 918.739 408.944 918.83 410.194C918.921 411.443 918.773 412.698 918.395 413.886C918.016 415.074 917.415 416.172 916.625 417.118C915.835 418.063 914.872 418.837 913.79 419.395C912.709 419.954 911.531 420.285 910.323 420.372V420.372Z"
        fill="#CCCCCC"
      />
      <path
        d="M1000.3 414.326L953.881 417.705C951.444 417.882 949.034 417.05 947.181 415.393C945.328 413.735 944.184 411.387 944.001 408.865C943.817 406.343 944.609 403.854 946.203 401.946C947.796 400.037 950.06 398.865 952.497 398.688L998.919 395.309C1001.36 395.132 1003.77 395.963 1005.62 397.621C1007.47 399.279 1008.62 401.627 1008.8 404.149C1008.98 406.671 1008.19 409.16 1006.6 411.068C1005 412.977 1002.74 414.149 1000.3 414.326Z"
        fill="#CCCCCC"
      />
      <path
        d="M798.582 501.454L752.159 504.832C749.724 505.006 747.317 504.173 745.467 502.515C743.616 500.858 742.474 498.511 742.291 495.992C742.107 493.472 742.898 490.985 744.489 489.077C746.079 487.169 748.34 485.996 750.775 485.815L797.197 482.437C798.405 482.347 799.619 482.505 800.769 482.901C801.92 483.297 802.985 483.923 803.904 484.744C804.823 485.565 805.577 486.564 806.123 487.685C806.67 488.806 806.998 490.026 807.089 491.276C807.18 492.525 807.032 493.78 806.653 494.968C806.275 496.156 805.673 497.254 804.883 498.199C804.093 499.145 803.13 499.919 802.049 500.477C800.967 501.035 799.789 501.367 798.582 501.454Z"
        fill="#CCCCCC"
      />
      <path
        d="M937.54 794.312L774.094 806.208C771.657 806.386 769.247 805.554 767.395 803.896C765.542 802.238 764.398 799.89 764.214 797.369C764.031 794.847 764.823 792.358 766.416 790.449C768.009 788.541 770.273 787.369 772.71 787.191L936.156 775.295C937.362 775.208 938.575 775.367 939.724 775.763C940.874 776.16 941.938 776.787 942.855 777.608C943.773 778.428 944.526 779.427 945.071 780.547C945.617 781.667 945.945 782.887 946.036 784.135C946.126 785.384 945.979 786.638 945.601 787.825C945.223 789.012 944.623 790.11 943.834 791.055C943.045 792 942.083 792.774 941.003 793.333C939.923 793.892 938.746 794.225 937.54 794.312V794.312Z"
        fill="#CCCCCC"
      />
      <path
        d="M1140.64 779.53L977.192 791.426C974.755 791.604 972.345 790.772 970.492 789.114C968.64 787.457 967.496 785.108 967.312 782.587C967.129 780.065 967.92 777.576 969.514 775.667C971.107 773.759 973.371 772.587 975.808 772.41L1139.25 760.514C1140.46 760.426 1141.67 760.585 1142.82 760.981C1143.97 761.378 1145.04 762.005 1145.95 762.826C1146.87 763.647 1147.62 764.645 1148.17 765.765C1148.72 766.885 1149.04 768.105 1149.13 769.353C1149.22 770.602 1149.08 771.856 1148.7 773.043C1148.32 774.23 1147.72 775.328 1146.93 776.273C1146.14 777.218 1145.18 777.992 1144.1 778.551C1143.02 779.11 1141.84 779.443 1140.64 779.53V779.53Z"
        fill="#CCCCCC"
      />
      <path
        d="M1143.26 815.562L858.923 836.257C856.486 836.435 854.076 835.603 852.224 833.945C850.371 832.287 849.227 829.939 849.043 827.417C848.86 824.896 849.652 822.407 851.245 820.498C852.838 818.589 855.102 817.418 857.539 817.24L1141.88 796.545C1143.08 796.456 1144.3 796.614 1145.45 797.01C1146.6 797.406 1147.66 798.032 1148.58 798.853C1149.5 799.674 1150.26 800.673 1150.8 801.794C1151.35 802.915 1151.68 804.135 1151.77 805.384C1151.86 806.634 1151.71 807.889 1151.33 809.077C1150.95 810.265 1150.35 811.363 1149.56 812.308C1148.77 813.253 1147.81 814.027 1146.73 814.586C1145.65 815.144 1144.47 815.476 1143.26 815.562Z"
        fill="#CCCCCC"
      />
      <path
        d="M934.881 757.78L771.435 769.676C768.998 769.853 766.588 769.022 764.736 767.364C762.883 765.706 761.739 763.358 761.555 760.836C761.372 758.314 762.164 755.825 763.757 753.917C765.35 752.008 767.614 750.836 770.051 750.659L933.497 738.763C935.934 738.586 938.343 739.417 940.196 741.075C942.049 742.733 943.193 745.081 943.377 747.603C943.56 750.125 942.768 752.614 941.175 754.522C939.582 756.431 937.318 757.603 934.881 757.78Z"
        fill="#CCCCCC"
      />
      <path
        d="M1024.86 751.734L978.438 755.113C976.001 755.29 973.591 754.459 971.739 752.801C969.886 751.143 968.742 748.795 968.558 746.273C968.375 743.751 969.167 741.262 970.76 739.354C972.353 737.445 974.617 736.273 977.054 736.096L1023.48 732.717C1025.91 732.54 1028.32 733.372 1030.18 735.029C1032.03 736.687 1033.17 739.035 1033.36 741.557C1033.54 744.079 1032.75 746.568 1031.15 748.476C1029.56 750.385 1027.3 751.557 1024.86 751.734V751.734Z"
        fill="#CCCCCC"
      />
      <path
        d="M823.139 838.862L776.716 842.24C774.28 842.418 771.87 841.586 770.017 839.928C768.164 838.271 767.02 835.922 766.837 833.401C766.653 830.879 767.445 828.39 769.038 826.481C770.632 824.573 772.896 823.401 775.332 823.224L821.755 819.845C822.962 819.755 824.176 819.913 825.327 820.309C826.478 820.705 827.543 821.331 828.461 822.152C829.38 822.973 830.134 823.973 830.681 825.093C831.227 826.214 831.555 827.434 831.646 828.684C831.737 829.933 831.589 831.188 831.211 832.376C830.832 833.564 830.231 834.662 829.441 835.608C828.65 836.553 827.687 837.327 826.606 837.885C825.525 838.443 824.347 838.775 823.139 838.862Z"
        fill="#CCCCCC"
      />
      <path
        d="M887.423 695.338C883.955 695.599 880.521 694.431 877.869 692.088L811.859 633.771C810.563 632.627 809.499 631.228 808.727 629.657C807.956 628.086 807.493 626.373 807.365 624.619C807.238 622.865 807.448 621.103 807.984 619.437C808.519 617.771 809.37 616.233 810.487 614.912L867.127 547.923C869.487 545.145 872.816 543.448 876.391 543.199C879.966 542.951 883.499 544.171 886.222 546.596C888.95 549.042 890.633 552.502 890.904 556.218C891.174 559.933 890.009 563.601 887.665 566.416L845.619 616.784C844.31 618.356 843.659 620.405 843.81 622.481C843.961 624.557 844.902 626.49 846.425 627.857L895.056 671.368C897.041 673.141 898.476 675.481 899.179 678.092C899.882 680.703 899.821 683.468 899.003 686.035C898.186 688.602 896.649 690.856 894.587 692.513C892.526 694.169 890.032 695.152 887.423 695.338L887.423 695.338Z"
        fill="#6C63FF"
      />
      <path
        d="M985.745 694.006C983.136 694.199 980.526 693.587 978.247 692.247C975.967 690.907 974.12 688.898 972.939 686.477C971.759 684.055 971.298 681.329 971.615 678.643C971.933 675.958 973.014 673.435 974.722 671.393L1016.54 621.299C1017.85 619.726 1018.5 617.677 1018.35 615.601C1018.2 613.525 1017.26 611.592 1015.73 610.226L966.838 566.477C965.485 565.267 964.375 563.794 963.571 562.143C962.767 560.493 962.284 558.696 962.15 556.855C962.016 555.015 962.234 553.167 962.79 551.417C963.347 549.667 964.232 548.05 965.395 546.657C967.739 543.863 971.057 542.144 974.631 541.872C978.204 541.6 981.744 542.798 984.482 545.205L1050.22 603.287C1051.52 604.432 1052.59 605.83 1053.36 607.401C1054.13 608.972 1054.59 610.685 1054.72 612.439C1054.85 614.194 1054.64 615.955 1054.1 617.621C1053.56 619.287 1052.71 620.825 1051.6 622.146L994.727 689.406C992.443 692.108 989.214 693.761 985.745 694.006Z"
        fill="#69D981"
      />
      <motion.path
        d="M39.0557 107.976C39.6413 98.7038 43.885 89.9187 51.0303 83.1875C58.1756 76.4564 67.758 72.2167 78.069 71.2244C88.38 70.2321 98.7494 72.5516 107.328 77.7695C115.907 82.9874 122.138 90.7644 124.91 99.714C125.441 99.697 125.97 99.6779 126.505 99.6779C135.959 99.6794 145.158 102.422 152.726 107.496C160.295 112.57 165.825 119.702 168.49 127.825C174.755 125.36 181.755 124.825 188.396 126.305C195.036 127.784 200.941 131.194 205.186 136H68.6883C51.9187 136 38.3409 123.561 39.0247 108.554C39.0335 108.362 39.0438 108.169 39.0557 107.976Z"
        fill="#A9B6FC"
        initial={{
          x: "100%",
        }}
        animate={{ x: 0}}
        transition={{
          repeat: Infinity,
          duration: 20,
          repeatType: "reverse",
        }}
      />


      <path
        d="M327.01 129.174L315.786 174.596L334.904 227.768L366.869 150.175L327.01 129.174Z"
        fill="#A0616A"
      />
      <path
        d="M345.574 900.968C345.574 900.968 349.482 938.266 347.596 940.949C345.71 943.631 343.823 946.313 343.84 951.646C343.857 956.979 321.162 970.499 309.759 959.927C298.357 949.356 305.885 933.295 305.885 933.295L317.152 901.204L345.574 900.968Z"
        fill="black"
      />
      <path
        d="M350.802 162.308C333.012 162.455 318.525 142.28 318.446 117.245C318.366 92.2108 332.723 71.7968 350.513 71.6494C368.303 71.502 382.789 91.6771 382.869 116.712C382.948 141.746 368.591 162.16 350.802 162.308Z"
        fill="#A0616A"
      />
      <path
        d="M331.938 189.13L356.676 174.916L372.518 436.775L268.339 448.305L303.483 178.698L319.52 157.286L331.938 189.13Z"
        fill="#E6E6E6"
      />
      <path
        d="M260.828 469.699C260.828 469.699 289.445 530.791 268.602 530.964C247.76 531.137 241.914 480.522 241.914 480.522L260.828 469.699Z"
        fill="#A0616A"
      />
      <path
        d="M378.305 468.726C378.305 468.726 350.078 530.289 370.921 530.116C391.764 529.944 397.287 479.235 397.287 479.235L378.305 468.726Z"
        fill="#A0616A"
      />
      <path
        d="M348.55 50.3337L357.999 42.256L356.121 47.6045L363.691 44.8753L361.805 47.5574L371.271 44.8125L367.498 50.1768C367.498 50.1768 382.656 50.0512 384.619 71.3668L394.102 73.9547L390.337 81.9854L390.363 89.9846L386.59 95.3489L386.616 103.348L382.869 116.712L379.02 98.0782C379.02 98.0782 361.983 103.552 354.336 82.2837C354.336 82.2837 352.467 90.2987 342.985 87.7108L327.945 125.166C327.945 125.166 325.998 108.633 319.358 106.297C312.719 103.96 316.441 82.5977 316.441 82.5977C316.441 82.5977 322.099 74.5513 322.082 69.2185C322.065 63.8857 327.733 58.5057 331.522 58.4743C335.312 58.443 344.752 47.6988 344.752 47.6988L348.55 50.3337Z"
        fill="#040221"
      />
      <path
        d="M233.167 681.287C239.44 693.527 287.737 764.269 320.042 811.252C329.009 824.27 336.737 835.485 341.991 843.094C346.598 849.749 349.301 853.646 349.301 853.646L363.698 810.756L369.948 792.146L369.853 791.987L365.87 784.9L327.891 717.219L288.006 646.168L319.928 555.243L280.112 547.573L253.668 549.979L247.909 550.507C247.909 550.507 223.634 662.7 233.167 681.287Z"
        fill="#B8B4EE"
      />
      <path
        d="M345.656 899.007C348.13 902.453 352.398 897.991 357.016 890.38C366.573 874.622 377.697 845.411 377.697 845.411L390.833 805.305C389.374 793.372 376.371 791.96 369.853 791.987C367.636 792.005 366.159 792.177 366.159 792.177L365.067 794.479L341.991 843.094C341.991 843.094 338.043 888.404 345.656 899.007Z"
        fill="black"
      />
      <path
        d="M365.012 432.211L378.277 432.101L375.047 608.117L349.488 912.308L305.908 912.669L324.159 693.864L314.294 571.289L247.909 550.506L266.467 427.695L365.012 432.211Z"
        fill="#B8B4EE"
      />
      <path
        d="M360.047 167.237L379.407 188.999L415.493 215.365L402.764 383.459L401.175 479.466C401.175 479.466 426.079 564.587 407.199 586.075C388.319 607.564 367.451 599.737 371.096 554.377C374.742 509.016 382.219 476.956 346.065 429.259C309.911 381.562 340.721 156.141 360.047 167.237Z"
        fill="black"
      />
      <path
        d="M321.56 155.004L297.88 176.784L265.745 201.049L242.335 585.217L297.361 608.76C297.361 608.76 326.393 165.378 321.56 155.004Z"
        fill="black"
      />
      <path
        d="M400.343 221.266L409.14 213.832L415.493 218.474C415.493 218.474 423.089 223.744 425.043 242.393C426.998 261.042 446.311 375.541 446.311 375.541C446.311 375.541 448.265 394.19 438.893 426.266C429.521 458.342 393.698 514.635 393.698 514.635L359.464 474.921L410.343 386.505L396.851 314.622L400.343 221.266Z"
        fill="black"
      />
      <path
        d="M280.904 200.923L265.745 201.049L241.529 331.907L234.467 494.621L270.409 475.658L270.036 358.337L280.904 200.923Z"
        fill="#04030C"
      />
    </svg>
  );
};

export default StandingHuman;
